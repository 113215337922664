@media (max-width: 767px) {
  #contentId table {
    min-width: 200px;
  }
  .menu-bar {
    display: inherit;
    justify-content: center;
  }
  #root, .dart-etgAccess {
    margin-top: 30px;
  }
  .container-center {
    display: flex;
    justify-content: center;
  }
  .navbar {
    position: static !important;
  }
  .mobile-navbar {
    padding: 10px;
    justify-content: space-between !important;
    display: flex !important;
    max-height: 96px;
  }
  #tg_toc_id {
    padding-bottom: 45px !important;
  }
}

.import-card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 20px;
  background-color: #f4f4f4;
}

.import-card {
  background: white;
  border: #999;
  border-radius: 10px;
  box-shadow: 0 4px 8px #999;
  padding: 20px;
  margin: 10px 0;
  width: 100%;
  max-width: 400px;
  transition: transform 0.2s, box-shadow 0.2s;
}



.text-center {
  text-align: center;
}




.import-form-control {
  width: 100%;
  padding: 8px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  transition: border-color 0.3s;
}

.form-control:focus {
  border-color: #007bff;
  outline: none;
}
